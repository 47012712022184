<div class="controls">
  <!-- in case of an error -->
  <app-status [status]="404" [url]="src.fileValue.fileUrl" [representation]="'audio'" *ngIf="failedToLoad">
  </app-status>

  <div class="file-representation">
    <div class="container">
      <div class="contents">
        <div class="icon">
          <mat-icon> audio_file </mat-icon>
        </div>
        <div *ngIf="failedToLoad">Your browser does not support the audio element.</div>
        <div *ngIf="audio" class="audio-player">
          <audio id="audio" preload="auto" (timeupdate)="onTimeUpdate($event)">
            <source [src]="audio" type="audio/mpeg" />
          </audio>
        </div>
      </div>
    </div>
    <div class="toolbar">
      <!-- toolbar -->
      <div class="action horizontal bottom">
        <!-- three dot menu to download and replace file -->
        <button mat-icon-button [matMenuTriggerFor]="more">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #more="matMenu" class="representation-menu">
          <button class="menu-content" mat-menu-item (click)="openIIIFnewTab()">Open audio in new tab</button>
          <button class="menu-content" mat-menu-item [cdkCopyToClipboard]="src.fileValue.fileUrl">
            Copy audio URL to clipboard
          </button>
          <button class="menu-content" mat-menu-item (click)="download(src.fileValue.fileUrl)">Download audio</button>
          <button class="menu-content" mat-menu-item (click)="openReplaceFileDialog()">Replace file</button>
        </mat-menu>
        <button mat-icon-button (click)="togglePlay()" *ngIf="isPaused()">
          <mat-icon> play_circle </mat-icon>
        </button>
        <button mat-icon-button (click)="togglePlay()" *ngIf="!isPaused()">
          <mat-icon> pause_circle </mat-icon>
        </button>
        <div class="current-time-span">
          <span> {{ parseTime(currentTime) }} / {{ parseTime(getDuration()) }} </span>
        </div>
        <div class="time-slider-container fill-remaining-space">
          <mat-slider class="time-slider" min="0" [max]="getDuration()" step="1" #ngSlider
            ><input
              matSliderThumb
              [value]="currentTime"
              (input)="
                                onSliderChangeEnd({
                                    source: ngSliderThumb,
                                    parent: ngSlider,
                                    value: ngSliderThumb.value
                                })
                            "
              #ngSliderThumb="matSliderThumb"
          /></mat-slider>
        </div>
        <button mat-icon-button (click)="toggleMute()" *ngIf="!isMuted()">
          <mat-icon> volume_up </mat-icon>
        </button>
        <button mat-icon-button (click)="toggleMute()" *ngIf="isMuted()">
          <mat-icon> volume_off </mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>
