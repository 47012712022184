<!-- select user to add to the team -->

<!-- header toolbar -->

<div class="app-toolbar transparent more-space-bottom" *ngIf="selectUserForm">
  <div class="app-toolbar-row toolbar-subtitle">
    <h3 class="mat-body subtitle">Increase your team</h3>
  </div>
  <div class="app-toolbar-row toolbar-form">
    <h2 class="mat-headline-6">{{ 'appLabels.form.user.title.add2project' | translate }}</h2>

    <span class="fill-remaining-space"></span>

    <span class="app-toolbar-action select-form">
      <form [formGroup]="selectUserForm" class="form" *ngIf="!loading">
        <!-- auto complete list to select user -->
        <div class="form-content">
          <mat-form-field class="large-field select-user">
            <input
              matInput
              [matAutocomplete]="user"
              [formControl]="selectUserForm.controls['addUser']"
              [placeholder]="'appLabels.form.user.title.select' | translate" />
            <button
              class="reset-button"
              mat-icon-button
              matSuffix
              *ngIf="selectUserForm.controls['addUser'].value"
              (click)="resetInput($event)"
              type="button">
              <mat-icon>close</mat-icon>
            </button>
            <mat-autocomplete
              #user="matAutocomplete"
              [displayWith]="displayWith"
              (optionSelected)="addUser($event.option.value)">
              <mat-option *ngFor="let user of filteredUsers$ | async; trackBy: trackByFn" [value]="user">
                {{ user?.label }}
              </mat-option>
            </mat-autocomplete>
            <mat-hint *ngIf="selectUserErrors.addUser"> {{ selectUserErrors.addUser }} </mat-hint>
          </mat-form-field>
        </div>
      </form>
    </span>

    <span class="fill-remaining-space"></span>

    <span class="app-toolbar-action select-form">
      <button
        mat-raised-button
        color="primary"
        [disabled]="!selectUserForm.valid"
        class="add-new create-user-btn"
        (click)="createUser()">
        New user
      </button>
    </span>
  </div>
</div>

<dasch-swiss-app-progress-indicator *ngIf="loading"></dasch-swiss-app-progress-indicator>
